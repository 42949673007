import BaseService from "@/http/baseService";
import DataPreparation from "@/libs/dataPreparation";

export default class UserService {
  static getUser(id) {
    return BaseService.getInstance().getData(`/users/${id}`)
  }
  static getUserProfile() {
    return BaseService.getInstance().getData(`/profile`)
  }
  static updateUser(payload) {
    return BaseService.getInstance().putData(`/profile`, payload.data)
  }
}