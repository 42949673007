export default {
  setAppointments(state, payload) {
    state.appointmentsData = payload
  },
  setAppointmentsTotal(state, payload) {
    state.appointmentsTotalData = payload
  },
  setAppointmentStats(state, payload) {
    state.appointmentStatsData = payload
  },
  setBlockTimes(state, payload) {
    state.blockTimesData = payload
  },
  setFilters(state, payload) {
    state.filters = payload
  },
  setAgents(state, payload) {
    state.agents = payload
  },
  setAppointmentOptions(state, payload) {
    state.appointmentOptionsData = payload
  },
  setAgentBlockTimes(state, payload) {
    state.agentBlockTimesData = payload
  }
}