export default {
  agents(state) {
    return state.agents
  },
  agentsTotal(state) {
    return state.agentsTotalData
  },
  agentFilters(state) {
    return state.agentFiltersData
  },
  agentOptions(state) {
    return state.agentOptionsData
  },
  agentDetails(state) {
    return state.agentDetailsData
  }
}