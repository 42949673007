export default [
  {
    path: '/invitations/rs/accept',
    name: 'invitations.rs.accept',
    meta: {
      layout: 'invitation',
    },
    component: () => import('@/views/pages/invitations/rs/Accept.vue'),
  },
  {
    path: '/invitations/rs/information',
    name: 'invitations.rs.information',
    meta: {
      layout: 'invitation',
    },
    component: () => import('@/views/pages/invitations/rs/Information.vue'),
  },
  {
    path: '/invitations/rs/confirm',
    name: 'invitations.rs.confirm',
    meta: {
      layout: 'invitation',
    },
    component: () => import('@/views/pages/invitations/rs/Confirm.vue'),
  }
]