import UserService from "@/http/user/userService";

export default {
  async getUser({ commit }, id) {
    await UserService.getUserProfile(id).then(response => {
      commit('setUser', response.data.result)
    })
  },
  async updateUser({ commit }, payload) {
    await UserService.updateUser(payload)
  }
}