export default {
  appointmentsData: [],
  appointmentsTotalData: 0,
  blockTimesData: [],
  appointmentStatsData: {
    today_appointments: 0,
    total_appointments: 0,
    accepted_appointments: 0,
  },
  filters: [],
  agentBlockTimesData: [],
  appointmentOptionsData: null,
  agents: {
    internal_agent: [],
    rs: [],
    ana_agent:[]

  },
}