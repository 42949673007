export default {
  path: '/projects/:id',
  name: 'projects.show',
  redirect: { name: 'projects.phases' },
  component: () => import('@/views/pages/projectManagement/projects/Project.vue'),
  children: [
    {
      path: '/projects/:id/phases',
      name: 'projects.phases',
      meta: {
        layout: 'vertical',
        button: {
          btnTitle: 'Add Phases',
          btnIcon: 'PlusIcon'
        },
        breadcrumbs: [
          {
            text: 'Project',
          },
          {
            text: 'Phases',
            active: true,
          },
        ],
      },
      component: () => import('@/views/pages/projectManagement/projects/Phases.vue'),
    },
    {
      path: '/projects/:id/agents',
      name: 'projects.agents',
      meta: {
        layout: 'vertical',
        button: {
          btnTitle: 'Invite',
          btnIcon: 'PlusIcon'
        },
        breadcrumbs: [
          {
            text: 'Project',
            to: {name: 'projects.show'}
          },
          {
            text: 'Internal Agents',
            active: true,
          },
        ],
      },
      component: () => import('@/views/pages/projectManagement/projects/InternalAgents.vue'),
    },
    {
      path: '/projects/:id/facility',
      name: 'projects.facility',
      meta: {
        layout: 'vertical',
        breadcrumbs: [
          {
            text: 'Project',
            to: {name: 'projects.show'}
          },
          {
            text: 'Facility',
            active: true,
          },
        ],
      },
      component: () => import('@/views/pages/projectManagement/projects/Feature.vue'),
    },
    {
      path: '/projects/:id/3d-view',
      name: 'projects.ThreeDView',
      meta: {
        layout: 'vertical',
        breadcrumbs: [
          {
            text: 'Project',
            to: {name: 'projects.show'}
          },
          {
            text: '3D View',
            active: true,
          },
        ],
      },
      component: () => import('@/views/pages/projectManagement/projects/ThreeDView.vue'),
    },
    {
      path: '/projects/:id/media',
      name: 'projects.media',
      meta: {
        layout: 'vertical',
        breadcrumbs: [
          {
            text: 'Project',
            to: {name: 'projects.show'}
          },
          {
            text: 'Media',
            active: true,
          },
        ],
      },
      component: () => import('@/views/pages/projectManagement/projects/Media.vue'),
    },
    {
      path: '/projects/:id/templates',
      name: 'projects.templates',
      meta: {
        layout: 'vertical',
        button: {
          btnTitle: 'Add Template',
          btnIcon: 'PlusIcon'
        },
        breadcrumbs: [
          {
            text: 'Project',
            to: {name: 'projects.show'}
          },
          {
            text: 'Templates',
            active: true,
          },
        ],
      },
      component: () => import('@/views/pages/projectManagement/projects/Template.vue'),
      children: [
        {
          path: '/projects/:id/templates/:templateId?/main-info',
          name: 'projects.templates.mainInfo',
          meta: {
            layout: 'vertical',
            breadcrumbs: [
              {
                text: 'Project',
                to: {name: 'projects.show'}
              },
              {
                text: 'Templates',
                active: true,
              },
            ],
          },
          component: () => import('@/views/pages/projectManagement/projects/templates/MainInfo.vue'),
        },
        {
          path: '/projects/:id/templates/:templateId/media',
          name: 'projects.templates.media',
          meta: {
            layout: 'vertical',
            breadcrumbs: [
              {
                text: 'Project',
                to: {name: 'projects.show'}
              },
              {
                text: 'Templates',
                active: true,
              },
            ],
          },
          component: () => import('@/views/pages/projectManagement/projects/templates/Media.vue'),
        },
        {
          path: '/projects/:id/templates/:templateId/description',
          name: 'projects.templates.description',
          meta: {
            layout: 'vertical',
            breadcrumbs: [
              {
                text: 'Project',
                to: {name: 'projects.show'}
              },
              {
                text: 'Templates',
                active: true,
              },
            ],
          },
          component: () => import('@/views/pages/projectManagement/projects/templates/Description.vue'),
        },
        {
          path: '/projects/:id/templates/:templateId/features',
          name: 'projects.templates.features',
          meta: {
            layout: 'vertical',
            breadcrumbs: [
              {
                text: 'Project',
                to: {name: 'projects.show'}
              },
              {
                text: 'Templates',
                active: true,
              },
            ],
          },
          component: () => import('@/views/pages/projectManagement/projects/templates/Feature.vue'),
        }
      ]
    },
    {
      path: '/projects/:id/domain-setting',
      name: 'projects.domainSetting',
      meta: {
        layout: 'vertical',
        breadcrumbs: [
          {
            text: 'Project',
            to: {name: 'projects.show'}
          },
          {
            text: 'Domain Setting',
            active: true,
          },
        ],
      },
      component: () => import('@/views/pages/projectManagement/projects/DomainSetting.vue'),
    },
    {
      path: '/projects/:id/online-setting',
      name: 'projects.onlineSetting',
      redirect: { name: 'projects.onlineSetting.branding' },
      meta: {
        layout: 'vertical',
        breadcrumbs: [
          {
            text: 'Project',
            to: {name: 'projects.show'}
          },
          {
            text: 'Online Setting',
            active: true,
          },
        ],
      },
      component: () => import('@/views/pages/projectManagement/projects/onlineSetting/OnlineSetting.vue'),
      children: [
        {
          path: '/projects/:id/online-setting/branding',
          name: 'projects.onlineSetting.branding',
          meta: {
            layout: 'vertical',
            breadcrumbs: [
              {
                text: 'Project',
                to: {name: 'projects.show'}
              },
              {
                text: 'Branding',
                active: true,
              },
            ],
          },
          component: () => import('@/views/pages/projectManagement/projects/onlineSetting/Branding.vue'),
        },
        {
          path: '/projects/:id/online-setting/overview',
          name: 'projects.onlineSetting.description',
          meta: {
            layout: 'vertical',
            breadcrumbs: [
              {
                text: 'Project',
                to: {name: 'projects.show'}
              },
              {
                text: 'Description',
                active: true,
              },
            ],
          },
          component: () => import('@/views/pages/projectManagement/projects/onlineSetting/Description.vue'),
        },
        {
          path: '/projects/:id/online-setting/media',
          name: 'projects.onlineSetting.media',
          meta: {
            layout: 'vertical',
            breadcrumbs: [
              {
                text: 'Project',
                to: {name: 'projects.show'}
              },
              {
                text: 'Media',
                active: true,
              },
            ],
          },
          component: () => import('@/views/pages/projectManagement/projects/onlineSetting/Media.vue'),
        },
        {
          path: '/projects/:id/online-setting/neighborhood',
          name: 'projects.onlineSetting.addresses',
          meta: {
            layout: 'vertical',
            breadcrumbs: [
              {
                text: 'Project',
                to: {name: 'projects.show'}
              },
              {
                text: 'Addresses',
                active: true,
              },
            ],
          },
          component: () => import('@/views/pages/projectManagement/projects/onlineSetting/Addresses.vue'),
        }
      ]
    },
    {
      path: '/projects/:id/properties',
      name: 'projects.properties',
      meta: {
        layout: 'vertical',
        breadcrumbs: [
          {
            text: 'Project',
            to: {name: 'projects.show'}
          },
          {
            text: 'Properties',
            active: true,
          },
        ],
      },
      component: () => import('@/views/pages/projectManagement/projects/Property.vue'),
    },
  ]
}