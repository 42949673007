export default {
  projectsData: [],
  project: null,
  phasesData: [],
  phasesTotalData: 0,
  templatesData: [],
  projectAgentsData: [],
  projectAgentsTotalData: 0,
  setFeaturesWithProjectFeaturesData: [],
  featuresData: [],
  photosData: [],
  threeDViewData: null,
  threeDWalkData: null,
  videoData: [],
  planData: [],
  agentsData: [],
  addressesDaa: [],
  onlineSettingData: null,
  languagesData: [],
  propertiesData: [],
  propertiesTotalData: 0,
  mainInfoTemplateData: [],
  templatePhotosData: [],
  templateVideoData: [],
  templatePlanData: [],
  templateThreeDWalkData: null,
  templateFeaturesData: [],
  templateDescriptionData: [],
  propertyFiltersData: [],
  onlineSettingCoverData: [],
  onlineSettingSecondaryCoverData: [],
  templateOptionsData: null,
  propertyDetailsData: null
}