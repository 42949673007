import ProjectService from "@/http/project/projectService";
export default {
  async getComplex({ commit }, payload) {
    await ProjectService.getComplex(payload).then(response => {
      commit('setComplex', response.data.result.data)
      commit('setComplexTotal', response.data.result.meta.total)
    })
  },
  async createNewComplex({ commit }, id) {
    return await ProjectService.createNewComplex(id)
  },
  async getThreeDView({ commit }, id) {
    await ProjectService.getPhaseThreeDView(id).then(response => { commit('setProjectThreeDView', response.data.result) })
  },
  async removeComplex({ commit }, id) {
    return await ProjectService.removeComplex(id)
  },
  async getMedia({ commit }, id) {
    await ProjectService.getPhasePhotos(id).then(response => { commit('setPhotos', response.data.result.data) })
    await ProjectService.getPhaseVideo(id).then(response => { commit('setVideo', response.data.result.data) })
    await ProjectService.getPhasePlan(id).then(response => { commit('setPlan', response.data.result.data) })
  },
  async getPhaseDetails({ commit }, id) {
    return await ProjectService.getPhaseDetails(id).then(response => {
      commit('setPhaseDetails', response.data.result)
      return response.data.result
    })
  },
  async updatePhase({ commit }, payload) {
    await ProjectService.updatePhase(payload)
  },
  async deleteProperty({ commit }, id) {
    return await ProjectService.deletePhaseProperty(id)
  },
  async getPhaseProperties({ commit }, payload) {
    await ProjectService.getPhaseProperties(payload).then(response => {
      commit('setPhaseProperties', response.data.result.data)
      commit('setPhasePropertiesTotal', response.data.result.meta.total)
    })
  },
  async createNewProperty({ commit }, payload) {
    return await ProjectService.createNewPhaseProperty(payload)
  },
  async updateProperty({ commit }, payload) {
    return await ProjectService.updatePhaseProperty(payload)
  },
  async getRequiredForm({ commit }, id) {
    await ProjectService.getRequiredForm(id).then(response => {
      commit('setRequiredForm', response.data.result.data)
    })
  },
}