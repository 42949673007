import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import projectManagement from './ProjectManagement'
import auth from './Auth'
import rsManagement from './RsManagement'
import appointment from './Appointment'
import internalAgent from './internalAgentManagement'
import contactRequest from './contactRequest'
import user from './user'
import leadDetails from './leadDetails'
import invitations from './invitations'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    ...projectManagement,
    ...rsManagement,
    ...appointment,
    internalAgent,
    auth,
    user,
    contactRequest,
    leadDetails,
    invitations
  },
  strict: process.env.DEV,
})
