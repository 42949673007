import tableView from "@/router/appointmentManagement/tableView";
import calendarView from "@/router/appointmentManagement/calendarView";
import details from "@/router/appointmentManagement/details";
export default [
  {
    path: '/appointment',
    name: 'appointment',
    redirect: { name: 'appointment.tableView' },
    component: () => import('@/views/pages/appointmentManagement/Appointment.vue'),
    children: [
      tableView,
      calendarView,
      details
    ]
  }
]