export default {
  setProjectsData(state, payload) {
    state.projectsData = payload
  },
  setProjectsDetails(state, payload) {
    state.project = payload
  },
  setProjectAgents(state, payload) {
    state.projectAgentsData = payload
  },
  setProjectAgentsTotal(state, payload) {
    state.projectAgentsTotalData = payload
  },
  setOnlineSetting(state, payload) {
    state.onlineSettingData = payload
  },
  setLanguages(state, payload) {
    state.languagesData = payload
  },
  setTemplateDescription(state, payload) {
    state.templateDescriptionData = payload
  },
  setProperties(state, payload) {
    state.propertiesData = payload
  },
  setPropertiesTotal(state, payload) {
    state.propertiesTotalData = payload
  },
  setPropertyDetails(state, payload) {
    state.propertyDetailsData = payload
  },
  setAgents(state, payload) {
    state.agentsData = payload
  },
  setAddresses(state, payload) {
    state.addressesDaa = payload
  },
  setFeaturesWithProjectFeatures(state, payload) {
    state.setFeaturesWithProjectFeaturesData = payload
  },
  setFeatures(state, payload) {
    state.featuresData = payload
  },
  setPhases(state, payload) {
    state.phasesData = payload
  },
  setPhasesTotal(state, payload) {
    state.phasesTotalData = payload
  },
  setPropertyFilters(state, payload) {
    state.propertyFiltersData = payload
  },
  setTemplates(state, payload) {
    state.templatesData = payload
  },
  setTemplatePhotos(state, payload) {
    state.templatePhotosData = payload
  },
  setTemplateVideo(state, payload) {
    state.templateVideoData = payload
  },
  setTemplatePlan(state, payload) {
    state.templatePlanData = payload
  },
  setTemplateThreeDWalk(state, payload) {
    state.templateThreeDWalkData = payload
  },
  setTemplateFeatures(state, payload) {
    state.templateFeaturesData = payload
  },
  setMainInfoTemplate(state, payload) {
    state.mainInfoTemplateData = payload
  },
  setTemplateOptions(state, payload) {
    state.templateOptionsData = payload
  },
  setProjectPhotos(state, payload) {
    state.photosData = payload
  },
  setProjectThreeDView(state, payload) {
    state.threeDViewData = payload
  },
  setProjectThreeDWalk(state, payload) {
    state.threeDWalkData = payload
  },
  setProjectVideo(state, payload) {
    state.videoData = payload
  },
  setProjectPlan(state, payload) {
    state.planData = payload
  },
  setOnlineSettingCover(state, payload) {
    state.onlineSettingCoverData = payload
  },
  setOnlineSettingSecondaryCover(state, payload) {
    state.onlineSettingSecondaryCoverData = payload
  }
}