export default {
  lead(state) {
    return state.leadData
  },
  leadTotal(state) {
    return state.leadTotalData
  },
  sellOperationFilters(state) {
    return state.sellOperationFiltersData
  },
  reserveOperationFilters(state) {
    return state.reserveOperationFiltersData
  }
}