export default {
  setLead(state, payload) {
    state.leadData = payload
  },
  setLeadTotal(state, payload) {
    state.leadTotalData = payload
  },
  setSellOperationFilters(state, payload) {
    state.sellOperationFiltersData = payload
  },
  setReserveOperationFilters(state, payload) {
    state.reserveOperationFiltersData = payload
  }
}