import LeadDetails from "@/http/leadDetails/leadDetails";

export default {
  async getLead({ commit }, payload) {
    await LeadDetails.getLead(payload).then(response => {
      commit('setLead', response.data.result.data)
      commit('setLeadTotal', response.data.result.meta.total)
    })
  },
  async getSellOperationFilters({ commit }) {
    await LeadDetails.getSellOperationFilters().then(response => {
      commit('setSellOperationFilters', response.data.result)
    })
  },
  async getReserveOperationFilters({ commit }) {
    await LeadDetails.getReserveOperationFilters().then(response => {
      commit('setReserveOperationFilters', response.data.result)
    })
  },
  async setAsSold({ commit }, payload) {
    await LeadDetails.setAsSold(payload)
  },
  async setAsReserve({ commit }, payload) {
    await LeadDetails.setAsReserve(payload)
  }
}