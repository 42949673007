import { $themeConfig } from '@themeConfig'
import boolean from "vue-good-table/src/components/types/boolean";

export default {
  namespaced: true,
  state: {
    // isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    isVerticalMenuCollapsed:
      localStorage.getItem('isVerticalMenuCollapsed') !== null
        ? JSON.parse(localStorage.getItem('isVerticalMenuCollapsed'))
        : false,
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
      localStorage.setItem('isVerticalMenuCollapsed', val)
    },
  },
  actions: {},
}
