export default {
  listOfRs(state) {
    return state.listOfRsData
  },
  sentRequest(state) {
    return state.sentRequestData
  },
  sentRequestTotal(state) {
    return state.sentRequestTotalData
  },
  receivedRequestTotal(state) {
    return state.receivedRequestTotalData
  },
  receivedRequest(state) {
    return state.receivedRequestData
  },
  rsProjects(state) {
    return state.rsProjectsData
  },
  rsProjectsTotal(state) {
    return state.rsProjectsTotalData
  },
  rsMembers(state) {
    return state.rsMembersData
  },
  rsInformation(state) {
    return state.rsInformationData
  },
  requestDetails(state) {
    return state.requestDetailsData
  }
}