export default {
  appointments(state) {
    return state.appointmentsData
  },
  appointmentsTotal(state) {
    return state.appointmentsTotalData
  },
  appointmentStats(state) {
    return state.appointmentStatsData
  },
  blockTimes(state) {
    return state.blockTimesData
  },
  filters(state) {
    return state.filters
  },
  agentBlockTimes(state) {
    return state.agentBlockTimesData
  },
  agents(state) {
    return state.agents
  },
  appointmentOptions(state) {
    return state.appointmentOptionsData
  },
  mappedInternalAgents(state) {
    let result = {};
    state.agents.ana_agent.forEach(agent => {
      result[agent.id] = agent.name
    });
    return result;
  },
}