import RsService from "@/http/rsManagement/rsService";
export default {
  async getListOfRs({ commit }) {
    await RsService.getListOfRs().then(response => {
      commit('setListOfRs', response.data.result)
    })
  },
  async invite({ commit }, payload) {
    return await RsService.invite(payload)
  },
  async toggleStar({ commit }, id) {
    return await RsService.toggleStar(id)
  },
  async getSentRequest({ commit }, payload) {
    await RsService.getSentRequest(payload).then(response => {
      commit('setSentRequest', response.data.result.data)
      commit('setSentRequestTotal', response.data.result.meta.total)
    })
  },
  async getReceivedRequest({ commit }, payload) {
    await RsService.getReceivedRequest(payload).then(response => {
      commit('setReceivedRequest', response.data.result.data)
      commit('setReceivedRequestTotal', response.data.result.meta.total)
    })
  },
  async getRsMembers({ commit }, payload) {
    await RsService.getRsMembers(payload).then(response => {
      commit('setRsMembers', response.data.result)
    })
  },
  async getRsProjects({ commit }, payload) {
    await RsService.getRsProjects(payload).then(response => {
      commit('setRsProjects', response.data.result.data)
      commit('setRsProjectsTotal', response.data.result.meta.total)
    })
  },
  async getRsInformation({ commit }, id) {
    await RsService.getRsInformation(id).then(response => {
      commit('setRsInformation', response.data.result)
    })
  },
  async updateCommissionRate({ commit }, payload) {
    return await RsService.updateCommissionRate(payload)
  },
  async updateRequest({ commit }, payload) {
    return await RsService.updateRequest(payload)
  },
  async getRequestDetails({ commit }, id) {
    return await RsService.getRequestDetails(id).then(response => {
      commit('setRequestDetails', response.data.result)
    })
  },
}