export default {
  complexDetails( state) {
    return state.complexDetailsData
  },
  complexProperties(state) {
    return state.complexPropertiesData
  },
  complexPropertiesTotal(state) {
    return state.complexPropertiesTotalData
  },
  photos(state) {
    return state.photosData
  },
  video(state) {
    return state.videoData
  },
  plan(state) {
    return state.planData
  },
  complexThreeDView(state) {
    return state.complexThreeDViewData
  },
}