export default {
  setAgents(state, payload) {
    state.agents = payload
  },
  setAgentsTotal(state, payload) {
    state.agentsTotalData = payload
  },
  setAgentDetails(state, payload) {
    state.agentDetailsData = payload
  },
  setAgentFilters(state, payload) {
    state.agentFiltersData = payload
  },
  setAgentOptions(state, payload) {
    state.agentOptionsData = payload
  }
}