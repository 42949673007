export default {
  path: '/complex/:id',
  name: 'complex.show',
  redirect: {name: 'complex.property'},
  component: () => import('@/views/pages/projectManagement/complex/Complex.vue'),
  children: [
    {
      path: '/complex/:id/property',
      name: 'complex.property',
      meta: {
        layout: 'vertical',
        breadcrumbs: [
          {
            text: 'Phase',
          },
          {
            text: 'Complex',
          },
          {
            text: 'Properties',
            active: true,
          },
        ],
      },
      component: () => import('@/views/pages/projectManagement/complex/Property.vue'),
    },
    {
      path: '/complex/:id/media',
      name: 'complex.media',
      meta: {
        layout: 'vertical',
        breadcrumbs: [
          {
            text: 'Complex',
            to: {name: 'complex.show'}
          },
          {
            text: 'Media',
            active: true,
          },
        ],
      },
      component: () => import('@/views/pages/projectManagement/complex/Media.vue'),
    },
    {
      path: '/complex/:id/3d-view',
      name: 'complex.ThreeDView',
      meta: {
        layout: 'vertical',
        breadcrumbs: [
          {
            text: 'Complex',
            to: {name: 'complex.show'}
          },
          {
            text: '3D View',
            active: true,
          },
        ],
      },
      component: () => import('@/views/pages/projectManagement/complex/ThreeDView.vue'),
    },
  ]
}