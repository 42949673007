<template>
  <Component
    :is="require(`@/assets/images/svg/${name}.svg`)"
    v-bind="$attrs"
    @v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'SvgLoader',

  // Transparent wrapper component
  // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  inheritAttrs: false,

  props: {
    name: {
      type: String,
      required: true,
    }
  }
}
</script>
