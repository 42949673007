export default {
  complexData: [],
  complexTotalData: 0,
  photosData: [],
  videoData: [],
  planData: [],
  phaseDetailsData: null,
  phasePropertiesData: [],
  phasePropertiesTotalData: 0,
  requiredFormData: [],
  threeDViewData: null
}