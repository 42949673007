export default {
  setComplexDetails(state, payload) {
    state.complexDetailsData = payload
  },
  setComplexProperties(state, payload) {
    state.complexPropertiesData = payload
  },
  setComplexPropertiesTotal(state, payload) {
    state.complexPropertiesTotalData = payload
  },
  setPhotos(state, payload) {
    state.photosData = payload
  },
  setComplexThreeDView(state, payload) {
    state.complexThreeDViewData = payload
  },
  setVideo(state, payload) {
    state.videoData = payload
  },
  setPlan(state, payload) {
    state.planData = payload
  },
}