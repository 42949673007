import ProjectService from "@/http/project/projectService";
export default {
  async getProjects({ commit }) {
    await ProjectService.getProjects().then(response => {
      commit('setProjectsData', response.data.result)
    })
  },
  async getProjectDetails({ commit }, id) {
    await ProjectService.getProjectDetails(id).then(response => {
      commit('setProjectsDetails', response.data.result)
    })
  },
  async updateProject({ commit }, payload) {
    await ProjectService.updateProject(payload)
  },
  async deleteProject({ commit }, id) {
    await ProjectService.deleteProject(id)
  },
  async getPhases({ commit }, payload) {
    await ProjectService.getPhases(payload).then(response => {
      commit('setPhases', response.data.result.data)
      commit('setPhasesTotal', response.data.result.meta.total)
    })
  },
  async getProperties({ commit }, payload) {
    await ProjectService.getProperties(payload).then(response => {
      commit('setProperties', response.data.result.data)
      commit('setPropertiesTotal', response.data.result.meta.total)
    })
  },
  async deleteProperty({ commit }, id) {
    return await ProjectService.deleteProperty(id)
  },
  async getProperty({ commit }, payload) {
    return await ProjectService.getProperty(payload).then(response => {
      commit('setPropertyDetails', response.data.result)
      return response.data.result
    })
  },
  async getPropertyFilters({ commit }, payload) {
    await ProjectService.getPropertyFilters(payload).then(response => {
      commit('setPropertyFilters', response.data.result)
    })
  },
  async getTemplates({ commit }, payload) {
    await ProjectService.getTemplates(payload).then(response => {
      commit('setTemplates', response.data.result.data)
    })
  },
  async getMainInfoTemplate({ commit }, id) {
    return await ProjectService.getMainInfoTemplate(id).then(response => {
      commit('setMainInfoTemplate', response.data.result)
    })
  },
  async getTemplateOptions({ commit }) {
    return await ProjectService.getTemplateOptions().then(response => {
      commit('setTemplateOptions', response.data.result)
    })
  },
  async getTemplateMedia({ commit }, id) {
    await ProjectService.getTemplatePhotos(id).then(response => { commit('setTemplatePhotos', response.data.result.data) })
    await ProjectService.getTemplateVideo(id).then(response => { commit('setTemplateVideo', response.data.result.data) })
    await ProjectService.getTemplatePlan(id).then(response => { commit('setTemplatePlan', response.data.result.data) })
    await ProjectService.getTemplateThreeDWalk(id).then(response => { commit('setTemplateThreeDWalk', response.data.result.data) })
  },
  async getTemplateFeatures({ commit }, id) {
    await ProjectService.getTemplateFeatures(id).then(response => {
      commit('setTemplateFeatures', response.data.result)
    })
  },
  async updateTemplateFeatures({ commit }, payload) {
    await ProjectService.updateTemplateFeatures(payload)
  },
  async getProjectAgents({ commit }, payload) {
    await ProjectService.getProjectAgents(payload).then(response => {
      commit('setProjectAgents', response.data.result.data)
      commit('setProjectAgentsTotal', response.data.result.meta.total)
    })
  },
  async deleteAgent({ commit }, payload) {
    return await ProjectService.deleteAgent(payload)
  },
  async getAgents({ commit }, id) {
    await ProjectService.getAgents(id).then(response => {
      commit('setAgents', response.data.result)
    })
  },
  async getAddresses({ commit }, id) {
    return await ProjectService.getAddresses(id).then(response => {
      commit('setAddresses', response.data.result)
      return response.data.result
    })
  },
  async getOnlineSetting({ commit }, id) {
    return await ProjectService.getOnlineSetting(id).then(response => {
      commit('setOnlineSetting', response.data.result)
    })
  },
  async getLanguages({ commit }, id) {
    return await ProjectService.getLanguages(id).then(response => {
      commit('setLanguages', response.data.result)
    })
  },
  async getTemplateDescription({ commit }, id) {
    return await ProjectService.getTemplateDescription(id).then(response => {
      commit('setTemplateDescription', response.data.result)
    })
  },
  async updateOnlineSetting({ commit }, payload) {
    return await ProjectService.updateOnlineSetting(payload)
  },
  async updateLanguages({ commit }, payload) {
    return await ProjectService.updateLanguages(payload)
  },
  async deleteTemplate({ commit }, id) {
    return await ProjectService.deleteTemplate(id)
  },
  async updateTemplateDescription({ commit }, payload) {
    return await ProjectService.updateTemplateDescription(payload)
  },
  async updateOnlineSettingAutoTranslate({ commit }, payload) {
    return await ProjectService.updateOnlineSettingAutoTranslate(payload)
  },
  async getMedia({ commit }, id) {
    await ProjectService.getPhotos(id).then(response => { commit('setProjectPhotos', response.data.result.data) })
    await ProjectService.getVideo(id).then(response => { commit('setProjectVideo', response.data.result.data) })
    await ProjectService.getPlan(id).then(response => { commit('setProjectPlan', response.data.result.data) })
  },
  async getThreeDView({ commit }, id) {
    await ProjectService.getThreeDView(id).then(response => { commit('setProjectThreeDView', response.data.result) })
  },
  async getThreeDWalk({ commit }, id) {
    await ProjectService.getThreeDWalk(id).then(response => { commit('setProjectThreeDWalk', response.data.result) })
  },
  async getOnlineSettingMedia({ commit }, id) {
    await ProjectService.getPhotos(id, 'cover').then(response => { commit('setOnlineSettingCover', response.data.result.data) })
    await ProjectService.getPhotos(id, 'secondary_cover').then(response => { commit('setOnlineSettingSecondaryCover', response.data.result.data) })
  },
  async getFeaturesWithProjectFeatures({ commit }, id) {
    await ProjectService.getFeaturesWithProjectFeatures(id).then(response => {
      commit('setFeaturesWithProjectFeatures', response.data.result)
    })
  },
  async getFeatures({ commit }, id) {
    await ProjectService.getFeatures(id).then(response => {
      commit('setFeatures', response.data.result)
    })
  },
  async inviteUser({ commit }, payload) {
    return await ProjectService.inviteUser(payload)
  },
  async createNewProject({ commit }, payload) {
    return await ProjectService.createNewProject(payload)
  },
  async createNewPhase({ commit }, id) {
    return await ProjectService.createNewPhase(id)
  },
  async uploadPropertyFile({ commit }, payload) {
    return await ProjectService.uploadPropertyFile(payload)
  },
  async removePhase({ commit }, id) {
    return await ProjectService.removePhase(id)
  },
  async updateAddresses({ commit }, payload) {
    return await ProjectService.updateAddresses(payload)
  },
  async createNewTemplate({ commit }, payload) {
    return await ProjectService.createNewTemplate(payload)
  },
  async createNewProperty({ commit }, payload) {
    return await ProjectService.createNewProperty(payload)
  },
  async updateProperty({ commit }, payload) {
    return await ProjectService.updateProperty(payload)
  },
  async updateTemplateDetails({ commit }, payload) {
    await ProjectService.updateTemplateDetails(payload)
  },
  async updateFeatures({ commit }, payload) {
    await ProjectService.updateFeatures(payload)
  },
  async removeMedia({ commit }, payload) {
    return await ProjectService.removeMedia(payload)
  },
  async uploadFile({ commit }, payload) {
    return await ProjectService.uploadFile(payload)
  },
  async downloadFile({ commit }, id) {
    return await ProjectService.downloadFile(id)
  }
}