import axios from "axios";
import Vue from 'vue';
import vueInstance from "@/main";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default class BaseService {
  static instance;
  axiosInstance;

  constructor(axios) {
    this.axiosInstance = axios
  }

  static getInstance() {
    if (this.instance === undefined) {
      this.instance = new BaseService(
        axios.create({
          baseURL: 'https://lastfloors-api.defraged.com',
          headers: {
            'Content-Type': 'application/json',
          },
        })
      )

      this.instance.axiosInstance.interceptors.request.use(config => {
        if (localStorage.getItem('token')) {
          config.headers["Authorization"] = `Bearer ${localStorage.getItem('token')}`
        }
        return config
      })

      this.instance.axiosInstance.interceptors.response.use((response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      }, error => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger

        let status = error.response.status;
        let errorData = error.response.data;
        let message = errorData.message;

        if (!message && errorData.data) {
          if (Array.isArray(errorData.data)) {

            message = errorData.data[0];
          }
          else if (typeof errorData.data === 'string') {
            message = errorData.data;
          }
          else if (typeof errorData.data === 'object') {
            message = '';
            Object.values(errorData.data).forEach(element => {
              if (typeof element === 'string')
                message += `${element} \n`;
              else if (Array.isArray(element) && element.length > 0)
                message += `${element[0]} \n`;

            });
          }

        }

        if (status == 401) {
          //destroy current token if exists
          localStorage.removeItem('token')
          if (vueInstance.$route.name !== 'login') {
            window.location.href = '/login';
          }
        }

        vueInstance.$toast({
          component: ToastificationContent,
          props: {
            title: vueInstance.$t('global.error'),
            icon: 'BellIcon',
            text: message || vueInstance.$t('global.requestFailed'),
            variant: 'danger',
          },
        })

        return Promise.reject(error);
      });

    }

    return this.instance
  }

  getData(url, options= null) {
    return this.axiosInstance.get(url, options)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error.response)
      })
  }

  postData(path, payload, options= null) {
    return this.axiosInstance.post(path, payload, options)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error.response)
      })
  }

  putData(path, payload, params = null) {
    return this.axiosInstance.put(path, payload, params)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error.response)
      })
  }

  patchData(path, payload, options= null) {
    return this.axiosInstance.patch(path, payload, options)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error.response)
      })
  }

  deleteData(path, options= null) {
    return this.axiosInstance.delete(path, options)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error.response)
      })
  }

  getBaseUrl() {
    return this.axiosInstance.defaults.baseURL
  }
}