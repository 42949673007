import BaseService from "@/http/baseService";

export default class AuthService {
  static login(payload) {
    return BaseService.getInstance().postData('/login', payload)
  }
  static logout() {
    return BaseService.getInstance().postData('/logout')
  }
  static forgotPassword(payload) {
    return BaseService.getInstance().postData('/forgot-password', payload)
  }
  static resetPassword(payload) {
    return BaseService.getInstance().postData('/reset-password', payload)
  }
}