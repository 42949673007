import BaseService from "@/http/baseService";
import DataPreparation from "@/libs/dataPreparation";

export default class InternalAgentService {
  static getAgents(payload) {
    const url = DataPreparation.apply(payload, '/ana/agents')
    return BaseService.getInstance().getData(url)
  }
  static getAgentDetails(id) {
    return BaseService.getInstance().getData(`/ana/agents/${id}`)
  }
  static deleteAgent(id) {
    return BaseService.getInstance().deleteData(`/ana/agents/${id}`)
  }
  static getAgentFilters() {
    return BaseService.getInstance().getData('/ana/agents/filters')
  }
  static getAgentOptions() {
    return BaseService.getInstance().getData('/ana/agents/options')
  }
  static invite(payload) {
    return BaseService.getInstance().postData('/ana/agents/invite', payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
  static updateAgent(payload) {
    return BaseService.getInstance().putData(`/ana/agents/${payload.id}`, payload.data)
  }
}