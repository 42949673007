import BaseService from "@/http/baseService";
import axios from "axios";
import DataPreparation from "@/libs/dataPreparation";

export default class ProjectService {
  static getProjects() {
    return BaseService.getInstance().getData('/projects')
  }
  static getProjectDetails(id) {
    return BaseService.getInstance().getData(`/projects/${id}`)
  }
  static getPhaseDetails(id) {
    return BaseService.getInstance().getData(`/phases/${id}`)
  }
  static getComplexDetails(id) {
    return BaseService.getInstance().getData(`/complex/${id}`)
  }
  static getProjectAgents(payload) {
    let url = DataPreparation.apply(payload, `/projects/${payload.id}/agents`)
    return BaseService.getInstance().getData(url)
  }
  static deleteAgent(payload) {
    return BaseService.getInstance().deleteData(`/projects/${payload.id}/agents/${payload.data.agentId}`)
  }
  static getAgents(id) {
    return BaseService.getInstance().getData(`/projects/${id}/agents/filter`)
  }
  static getAddresses(id) {
    return BaseService.getInstance().getData(`/projects/${id}/addresses`)
  }
  static getRequiredForm(id) {
    return BaseService.getInstance().getData(`phases/${id}/media?type=form`)
  }
  static getOnlineSetting(id) {
    return BaseService.getInstance().getData(`/projects/${id}/online-setting`)
  }
  static getLanguages(id) {
    return BaseService.getInstance().getData(`/projects/${id}/languages`)
  }
  static getTemplateDescription(id) {
    return BaseService.getInstance().getData(`/templates/${id}/description`)
  }
  static updateOnlineSetting(payload) {
    return BaseService.getInstance().putData(`/projects/${payload.id}/online-setting`, payload.data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
  static updateLanguages(payload) {
    return BaseService.getInstance().putData(`/projects/${payload.id}/languages`, payload.data)
  }
  static updateTemplateDescription(payload) {
    return BaseService.getInstance().putData(`/templates/${payload.templateId}/description`, payload.data)
  }
  static deleteTemplate(id) {
    return BaseService.getInstance().deleteData(`/templates/${id}`)
  }
  static updateOnlineSettingAutoTranslate(payload) {
    return BaseService
      .getInstance()
      .postData(`/${payload.type}/translate`, payload.data)
  }
  static updateTemplateDescriptionAutoTranslate(payload) {
    return BaseService
      .getInstance()
      .putData(`/projects/${payload.id}/online-setting/${payload.languageId}/auto-translate`, payload.data)
  }
  static getPhotos(id, type = 'image') {
    return BaseService.getInstance().getData(`/projects/${id}/media?type=${type}`)
  }
  static getPlan(id) {
    return BaseService.getInstance().getData(`/projects/${id}/media?type=plan`)
  }
  static getVideo(id) {
    return BaseService.getInstance().getData(`/projects/${id}/media?type=video`)
  }
  static getThreeDView(id) {
    return BaseService.getInstance().getData(`/projects/${id}/view3d`)
  }
  static getPhaseThreeDView(id) {
    return BaseService.getInstance().getData(`/phases/${id}/view3d`)
  }
  static getComplexThreeDView(id) {
    return BaseService.getInstance().getData(`/complexes/${id}/view3d`)
  }
  static getFeaturesWithProjectFeatures(id) {
    return BaseService.getInstance().getData(`/projects/${id}/facilities`)
  }
  static getFeatures(id) {
    return BaseService.getInstance().getData(`/projects/${id}/features`)
  }
  static updateProject(payload) {
    return BaseService.getInstance().putData(`/projects/${payload.id}`, payload.data)
  }
  static deleteProject(id) {
    return BaseService.getInstance().deleteData(`/projects/${id}`)
  }
  static updatePhase(payload) {
    return BaseService.getInstance().putData(`/phases/${payload.id}`, payload.data)
  }
  static deletePhaseProperty(id) {
    return BaseService.getInstance().deleteData(`/property/${id}`)
  }
  static updateComplex(payload) {
    return BaseService.getInstance().putData(`/complex/${payload.id}`, payload.data)
  }
  static getPhases(payload) {
    let url = DataPreparation.apply(payload, `/projects/${payload.id}/phases`)
    return BaseService.getInstance().getData(url)
  }
  static getComplex(payload) {
    let url = DataPreparation.apply(payload, `/phases/${payload.id}/complexes`)
    return BaseService.getInstance().getData(url)
  }
  static getProperties(payload) {
    let url = DataPreparation.apply(payload, `/properties?project=${payload.id}`, true)
    return BaseService.getInstance().getData(url)
  }
  static deleteProperty(id) {
    return BaseService.getInstance().deleteData(`/property/${id}`)
  }
  static getPhaseProperties(payload) {
    let url = DataPreparation.apply(payload, `/properties?phase=${payload.id}`, true)
    return BaseService.getInstance().getData(url)
  }
  static getComplexProperties(payload) {
    let url = DataPreparation.apply(payload, `/properties?complex=${payload.id}`, true)
    return BaseService.getInstance().getData(url)
  }
  static getProperty(payload) {
    return BaseService.getInstance().getData(`/property/${payload.propertyId}`)
  }
  static getTemplates(payload) {
    let url = DataPreparation.apply(payload, `/projects/${payload.id}/templates`)
    return BaseService.getInstance().getData(url)
  }
  static getMainInfoTemplate(id) {
    return BaseService.getInstance().getData(`/templates/${id}`)
  }
  static getTemplateOptions() {
    return BaseService.getInstance().getData(`/templates/options`)
  }
  static getTemplatePhotos(id) {
    return BaseService.getInstance().getData(`/templates/${id}/media?type=image`)
  }
  static getTemplatePlan(id) {
    return BaseService.getInstance().getData(`/templates/${id}/media?type=plan`)
  }
  static getTemplateThreeDWalk(id) {
    return BaseService.getInstance().getData(`/templates/${id}/media?type=3d_walk`)
  }
  static getPhasePhotos(id) {
    return BaseService.getInstance().getData(`/phases/${id}/media?type=image`)
  }
  static getPhasePlan(id) {
    return BaseService.getInstance().getData(`/phases/${id}/media?type=plan`)
  }
  static getPhaseVideo(id) {
    return BaseService.getInstance().getData(`/phases/${id}/media?type=video`)
  }
  static getTemplateVideo(id) {
    return BaseService.getInstance().getData(`/templates/${id}/media?type=video`)
  }
  static getTemplateFeatures(id) {
    return BaseService.getInstance().getData(`/templates/${id}/features`)
  }
  static getPropertyFilters(payload) {
    return BaseService.getInstance().getData(`/properties/filters?${payload.type}=${payload.id}`)
  }
  static updateTemplateFeatures(payload) {
    return BaseService.getInstance().putData(`/templates/${payload.templateId}/features`, payload.data)
  }
  static inviteUser(payload) {
    return BaseService.getInstance().postData(`/projects/${payload.id}/agents`, payload.data)
  }
  static createNewProject(payload) {
    return BaseService.getInstance().postData('/projects', payload)
  }
  static createNewPhase(id) {
    return BaseService.getInstance().postData(`/projects/${id}/phases`)
  }
  static uploadPropertyFile(payload) {
    return BaseService.getInstance().postData(`projects/${payload.id}/property/import`, payload.data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
  static removePhase(id) {
    return BaseService.getInstance().deleteData(`/phases/${id}`)
  }
  static updateAddresses(payload) {
    return BaseService.getInstance().putData(`/projects/${payload.id}/addresses`, payload.data)
  }
  static createNewComplex(id) {
    return BaseService.getInstance().postData(`phases/${id}/complexes`)
  }
  static removeComplex(id) {
    return BaseService.getInstance().deleteData(`complex/${id}`)
  }
  static createNewTemplate(payload) {
    return BaseService.getInstance().postData(`/projects/${payload.id}/templates`, payload.data)
  }
  static createNewProperty(payload) {
    return BaseService.getInstance().postData(`/properties`, payload.data)
  }
  static updateProperty(payload) {
    return BaseService.getInstance().putData(`/property/${payload.propertyId}`, payload.data)
  }
  static createNewPhaseProperty(payload) {
    return BaseService.getInstance().postData(`/properties`, payload.data)
  }
  static updatePhaseProperty(payload) {
    return BaseService.getInstance().putData(`/property/${payload.propertyId}`, payload.data)
  }
  static createNewComplexProperty(payload) {
    return BaseService.getInstance().postData(`/complex/${payload.id}/property`, payload.data)
  }
  static updateComplexProperty(payload) {
    return BaseService.getInstance().putData(`/complex/${payload.id}/property/${payload.propertyId}`, payload.data)
  }
  static updateTemplateDetails(payload) {
    return BaseService.getInstance().putData(`/templates/${payload.templateId}`, payload.data)
  }
  static updateFeatures(payload) {
    return BaseService.getInstance().putData(`/projects/${payload.id}/facilities`, payload.data)
  }
  static getComplexPhotos(id) {
    return BaseService.getInstance().getData(`/complexes/${id}/media?type=image`)
  }
  static getComplexPlan(id) {
    return BaseService.getInstance().getData(`/complexes/${id}/media?type=plan`)
  }
  static getComplexVideo(id) {
    return BaseService.getInstance().getData(`/complexes/${id}/media?type=video`)
  }
  static removeMedia(payload) {
    return BaseService.getInstance().deleteData(payload.url + '?' + payload.data.ids)
  }
  static uploadFile(payload) {
    return BaseService.getInstance().postData(payload.url, payload.data, payload.config)
  }
  static downloadFile(id) {
    return BaseService.getInstance().getData(`projects/${id}/property/import/sample`)
  }
}