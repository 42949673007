import ProjectService from "@/http/project/projectService";
export default {
  async getComplexDetails({ commit }, id) {
    return await ProjectService.getComplexDetails(id).then(response => {
      commit('setComplexDetails', response.data.result)
      return response.data.result
    })
  },
  async deleteProperty({ commit }, id) {
    return await ProjectService.deleteProperty(id)
  },
  async updateComplex({ commit }, payload) {
    await ProjectService.updateComplex(payload)
  },
  async getComplexProperties({ commit }, payload) {
    await ProjectService.getComplexProperties(payload).then(response => {
      commit('setComplexProperties', response.data.result.data)
      commit('setComplexPropertiesTotal', response.data.result.meta.total)
    })
  },
  async createNewProperty({ commit }, payload) {
    return await ProjectService.createNewComplexProperty(payload)
  },
  async updateProperty({ commit }, payload) {
    return await ProjectService.updateComplexProperty(payload)
  },
  async getComplexThreeDView({ commit }, id) {
    return await ProjectService.getComplexThreeDView(id).then(response => { commit('setComplexThreeDView', response.data.result) })
  },
  async getMedia({ commit }, id) {
    await ProjectService.getComplexPhotos(id).then(response => { commit('setPhotos', response.data.result.data) })
    await ProjectService.getComplexVideo(id).then(response => { commit('setVideo', response.data.result.data) })
    await ProjectService.getComplexPlan(id).then(response => { commit('setPlan', response.data.result.data) })
  },
}