import AppointmentService from "@/http/appointment/appointmentService";

export default {
  async getAppointments({ commit }, payload) {
    await AppointmentService.getAppointments(payload).then(response => {
      commit('setAppointments', response.data.result.data)
      commit('setAppointmentsTotal', response.data.result.meta.total)
    })
  },
  async getAppointmentStats({ commit }) {
    await AppointmentService.getAppointmentStats().then(response => {
      const data = response.data.result
      if (!Object.keys(data).length) return
      commit('setAppointmentStats', response.data.result)
    })
  },
  async getBlockTimes({ commit }) {
    await AppointmentService.getBlockTimes().then(response => {
      commit('setBlockTimes', response.data.result.data)
    })
  },
  async getFilters({ commit }) {
    await AppointmentService.getFilters().then(response => {
      commit('setFilters', response.data.result)
    })
  },
  async getAgents({ commit }) {
    await AppointmentService.getAgents().then(response => {
      commit('setAgents', response.data.result.data)
    })
  },
  async getAppointmentOptions({ commit }) {
    await AppointmentService.getAppointmentOptions().then(response => {
      commit('setAppointmentOptions', response.data.result)
    })
  },
  async getAgentBlockTimes({ commit }, payload) {
    await AppointmentService.getAgentBlockTimes(payload).then(response => {
      commit('setAgentBlockTimes', response.data.result)
    })
  },

  async updateAppointment({ commit }, payload) {
    return await AppointmentService.updateAppointment(payload)
  },

  async createNewAppointment({ commit }, payload) {
    return await AppointmentService.createNewAppointment(payload)
  },
  async declineAppointment({ commit }, id) {
    return await AppointmentService.declineAppointment(id)
  },
  async acceptAppointment({ commit }, id) {
    return await AppointmentService.acceptAppointment(id)
  },
  async updateBlockTime({ commit }, payload) {
    return await AppointmentService.updateBlockTime(payload)
  },

  async createNewBlockTime({ commit }, payload) {
    return await AppointmentService.createNewBlockTime(payload)
  },
  async setAsDone({ commit }, payload) {
    return await AppointmentService.setAsDone(payload)
  },
  async deleteAppointment({ commit, state }, id) {

    let appointments = [];
    let originalAppointments = state.appointmentsData;
    appointments = await state.appointmentsData.filter((appointment, index) => {
      return appointment.id !== id;
    });
    commit('setAppointments', appointments)
    AppointmentService.deleteAppointment(id).then().catch((err) => {
      commit('setAppointments', originalAppointments)
    })
  },
  async deleteBlockTime({ commit, state }, id) {
    let blockTimes = [];
    let originalBlockTimes = state.blockTimesData;
    blockTimes = await state.blockTimesData.filter((blockTime, index) => {
      return blockTime.id !== id;
    });
    commit('setBlockTimes', blockTimes)
    AppointmentService.deleteBlockTime(id).then().catch((err) => {
      commit('setBlockTimes', originalBlockTimes)
    })
  },
}