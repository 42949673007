export default {
  path: '/rs-management/connected',
  name: 'rsManagement.connected',
  component: () => import('@/views/pages/rsManagement/Connected.vue'),
  children: [
    {
      path: '/rs-management/connected/:id',
      name: 'rsManagement.rs',
      redirect: { name: 'rsManagement.projects' },
      component: () => import('@/views/pages/rsManagement/Rs.vue'),
      children: [
        {
          path: '/rs-management/:id/projects',
          name: 'rsManagement.projects',
          meta: {
            layout: 'vertical',
            breadcrumbs: [
              {
                text: 'Project',
                active: true,
              }
            ],
          },
          component: () => import('@/views/pages/rsManagement/Projects.vue'),
        },
        {
          path: '/rs-management/:id/members',
          name: 'rsManagement.rsMembers',
          meta: {
            layout: 'vertical',
            breadcrumbs: [
              {
                text: 'rsMembers',
                active: true,
              }
            ],
          },
          component: () => import('@/views/pages/rsManagement/RsMembers.vue'),
        },
        {
          path: '/rs-management/:id/information',
          name: 'rsManagement.information',
          meta: {
            layout: 'vertical',
            breadcrumbs: [
              {
                text: 'rsInformation',
                active: true,
              }
            ],
          },
          component: () => import('@/views/pages/rsManagement/RsInformation.vue'),
        }
      ]
    },
  ]
}