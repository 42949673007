import BaseService from "@/http/baseService";
import DataPreparation from "@/libs/dataPreparation";


export default class AppointmentService {
  static getAppointments(payload) {
    let url = DataPreparation.apply(payload, `/ana/appointments`)
    return BaseService.getInstance().getData(url)
  }
  static getAppointmentStats() {
    return BaseService.getInstance().getData('/ana/appointments/statistics')
  }
  static getBlockTimes() {
    return BaseService.getInstance().getData('/ana/blocks')
  }
  static getFilters() {
    return BaseService.getInstance().getData('/ana/appointments/filter')
  }
  static getAgents() {
    return BaseService.getInstance().getData('/ana/agents')
  }
  static getAppointmentOptions() {
    return BaseService.getInstance().getData('/ana/appointments/options')
  }
  static getAgentBlockTimes(payload) {
    return BaseService.getInstance().getData(`/ana/busy-times/${payload.id}/${payload.date}`)
  }

  static createNewAppointment(payload) {
    return BaseService.getInstance().postData('/ana/appointments', payload)
  }

  static declineAppointment(id) {
    return BaseService.getInstance().putData('/ana/appointments', id)
  }
  static acceptAppointment(id) {
    return BaseService.getInstance().putData('/ana/appointments', id)
  }

  static updateAppointment(payload) {
    return BaseService.getInstance().putData(`/ana/appointments/${payload.id}`, payload.data)
  }
  static getAppointmentDetails(id) {
    return BaseService.getInstance().getData(`/ana/appointments/${id}`)
  }
  static getBlockTimeDetails(id) {
    return BaseService.getInstance().getData(`/blocks/${id}`)
  }

  static createNewBlockTime(payload) {
    return BaseService.getInstance().postData('/ana/blocks', payload)
  }

  static updateBlockTime(payload) {
    return BaseService.getInstance().putData(`/blocks/${payload.id}`, payload.data)
  }

  static setAsDone(payload) {
    return BaseService.getInstance().putData(`/ana/set-as-done/${payload.id}`, payload.data)
  }

  static deleteAppointment(id) {
    return BaseService.getInstance().deleteData(`/ana/appointments/${id}`)
  }
  
  static deleteBlockTime(id) {
    return BaseService.getInstance().deleteData(`/ana/blocks/${id}`)
  }

}