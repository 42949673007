import BaseService from "@/http/baseService";

export default class InvitationsService {
  static createAccount(payload) {
    return BaseService.getInstance().postData(`/invitations/create`, payload)
  }
  static verifyAna(payload) {
    return BaseService.getInstance().patchData(`/ana/agents/verify`, payload)
  }

  static decline() {
    return BaseService.getInstance().putData(`/invitations/decline`)
  }
}