import ContactRequestService from "@/http/contactRequest/contactRequestService";

export default {
  async getRequests({ commit }, payload) {
    await ContactRequestService.getRequests(payload).then(response => {
      commit('setRequests', response.data.result.data)
    }).finally(() => {
      commit('setRequests', [
        {
          id: 1,
          lead: 'Milad',
          project: 'test',
          property: '1+1',
          source: 'facebook',
          register_date: '2020-09-09',
          phone: '+98 921 5420796',
          email: 'miladfathi021@gmail.com'
        }
      ])
    })
  }
}