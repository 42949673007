export default {
  projects(state) {
    return state.projectsData
  },
  projectDetails(state) {
    return state.project
  },
  phases(state) {
    return state.phasesData
  },
  phasesTotal(state) {
    return state.phasesTotalData
  },
  templates(state) {
    return state.templatesData
  },
  projectAgents(state) {
    return state.projectAgentsData
  },
  projectAgentsTotal(state) {
    return state.projectAgentsTotalData
  },
  addresses(state) {
    return state.addressesDaa
  },
  featuresWithProjectFeatures(state) {
    return state.setFeaturesWithProjectFeaturesData
  },
  features(state) {
    return state.featuresData
  },
  propertyFilters(state) {
    return state.propertyFiltersData
  },
  templateOptions(state) {
    return state.templateOptionsData
  },
  propertiesTotal(state) {
    return state.propertiesTotalData
  },
  projectPhotos(state) {
    return state.photosData
  },
  threeDView(state) {
    return state.threeDViewData
  },
  projectVideo(state) {
    return state.videoData
  },
  projectPlan(state) {
    return state.planData
  },
  agents(state) {
    return state.agentsData
  },
  onlineSetting(state) {
    return state.onlineSettingData
  },
  properties(state) {
    return state.propertiesData
  },
  mainInfoTemplate(state) {
    return state.mainInfoTemplateData
  },
  templatePhotos(state) {
    return state.templatePhotosData
  },
  templateVideo(state) {
    return state.templateVideoData
  },
  templatePlan(state) {
    return state.templatePlanData
  },
  templateThreeDWalk(state) {
    return state.templateThreeDWalkData
  },
  templateFeatures(state) {
    return state.templateFeaturesData
  },
  templateDescription(state) {
    return state.templateDescriptionData
  },
  propertyDetails(state) {
    return state.propertyDetailsData
  },
  onlineSettingCover(state) {
    return state.onlineSettingCoverData
  },
  onlineSettingSecondaryCover(state) {
    return state.onlineSettingSecondaryCoverData
  },
  languages(state) {
    return state.languagesData
  },
  threeDWalk(state) {
    return state.threeDWalkData
  }
}