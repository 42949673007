export default {
  path: '/phases/:id',
  name: 'phases.show',
  redirect: {name: 'phases.complex'},
  component: () => import('@/views/pages/projectManagement/phases/Phase.vue'),
  children: [
    {
      path: '/phases/:id/complex',
      name: 'phases.complex',
      meta: {
        layout: 'vertical',
        button: {
          btnTitle: 'Add Complex',
          btnIcon: 'PlusIcon'
        },
        breadcrumbs: [
          {
            text: 'Phases',
          },
          {
            text: 'Complex',
            active: true,
          },
        ],
      },
      component: () => import('@/views/pages/projectManagement/phases/Complex.vue'),
    },
    {
      path: '/phases/:id/media',
      name: 'phases.media',
      meta: {
        layout: 'vertical',
        breadcrumbs: [
          {
            text: 'Phase',
            to: {name: 'phases.show'}
          },
          {
            text: 'Media',
            active: true,
          },
        ],
      },
      component: () => import('@/views/pages/projectManagement/phases/Media.vue'),
    },
    {
      path: '/phases/:id/3d-view',
      name: 'phases.ThreeDView',
      meta: {
        layout: 'vertical',
        breadcrumbs: [
          {
            text: 'Phase',
            to: {name: 'phases.show'}
          },
          {
            text: '3D View',
            active: true,
          },
        ],
      },
      component: () => import('@/views/pages/projectManagement/phases/ThreeDView.vue'),
    },
    {
      path: '/phases/:id/required-form',
      name: 'phases.requiredForm',
      meta: {
        layout: 'vertical',
        breadcrumbs: [
          {
            text: 'Phase',
            to: {name: 'phases.show'}
          },
          {
            text: 'Required Form',
            active: true,
          },
        ],
      },
      component: () => import('@/views/pages/projectManagement/phases/RequiredForm.vue'),
    },
    {
      path: '/phases/:id/property',
      name: 'phases.property',
      meta: {
        layout: 'vertical',
        breadcrumbs: [
          {
            text: 'Phase',
            to: {name: 'phases.show'}
          },
          {
            text: 'Properties',
            active: true,
          },
        ],
      },
      component: () => import('@/views/pages/projectManagement/phases/Property.vue'),
    }
  ]
}