import projects from './projects'
import phases from './phases'
import complex from "./complex";
export default [
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/pages/projectManagement/ProjectManagement.vue'),
    children: [
      projects,
      phases,
      complex
    ]
  }
]