export default class DataPreparation {
  static apply(payload, url, hasQueryDefault = false) {
    let params = new URLSearchParams()

    if (!payload) {
      return url
    }

    if ('number' in payload) {
      params.append("page", payload.number)
    }

    if ('per_page' in payload) {
      params.append("per_page", payload.per_page)
    }

    if ('search' in payload) {
      params.append("search", payload.search)
    }

    if ('filters' in payload) {
      Object.keys(payload.filters).forEach(key => {
        let val = payload.filters[key]
        if (Array.isArray(payload.filters[key])) {
          val = payload.filters[key].join(',')
        }
        params.append(key, val)
      })
    }

    if (!!params.toString()) {
      if (hasQueryDefault) {
        url = `${url}&${params.toString()}`
      } else {
        url = `${url}?${params.toString()}`
      }
    }
    return url
  }

  static thousandSeparators(num) {
    return '$' + Number(num)
      .toString()
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
  }
}