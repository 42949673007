import BaseService from "@/http/baseService";
import DataPreparation from "@/libs/dataPreparation";

export default class RsService {
  static getListOfRs() {
    return BaseService.getInstance().getData('/ana/rs')
  }
  static getSentRequest(payload) {
    let url = DataPreparation.apply(payload, '/connections?type=invitation')
    return BaseService.getInstance().getData(url)
  }
  static getReceivedRequest(payload) {
    let url = DataPreparation.apply(payload, '/connections?type=request')
    return BaseService.getInstance().getData(url)
  }
  static getRsProjects(payload) {
    let url = `/ana/rs/${payload.id}/projects`
    let params = new URLSearchParams()

    if ('number' in payload) {
      params.append("page", payload.number)
    }

    if ('search' in payload) {
      params.append("search", payload.search)
    }

    if (!!params.toString()) {
      url = `${url}?${params.toString()}`
    }
    return BaseService.getInstance().getData(url)
  }
  static invite(payload) {
    return BaseService.getInstance().postData('/connections', payload)
  }
  static toggleStar(id) {
    return BaseService.getInstance().postData(`/favorite/${id}`)
  }
  static updateCommissionRate(payload) {
    return BaseService.getInstance().putData(
      `/rs/${payload.id}/projects/${payload.projectSelected}`,
      payload.data
    )
  }
  static updateRequest(payload) {
    return BaseService.getInstance().patchData(
      `/connections/${payload.id}`,
      payload.data
    )
  }
  static getRsMembers(id) {
    return BaseService.getInstance().getData(`/ana/rs/${id}/agents`)
  }
  static getRsInformation(id) {
    return BaseService.getInstance().getData(`/ana/rs/${id}`)
  }
  static getRequestDetails(id) {
    return BaseService.getInstance().getData(`/connections/${id}`)
  }
}