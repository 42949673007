export default {
  complex(state) {
    return state.complexData
  },
  complexTotal(state) {
    return state.complexTotalData
  },
  photos(state) {
    return state.photosData
  },
  video(state) {
    return state.videoData
  },
  plan(state) {
    return state.planData
  },
  threeDView(state) {
    return state.threeDViewData
  },
  phaseDetails(state) {
    return state.phaseDetailsData
  },
  phaseProperties(state) {
    return state.phasePropertiesData
  },
  phasePropertiesTotal(state) {
    return state.phasePropertiesTotalData
  },
  requiredForm(state) {
    return state.requiredFormData
  }
}