export default {
  setComplex(state, payload) {
    state.complexData = payload
  },
  setComplexTotal(state, payload) {
    state.complexTotalData = payload
  },
  setPhotos(state, payload) {
    state.photosData = payload
  },
  setProjectThreeDView(state, payload) {
    state.threeDViewData = payload
  },
  setVideo(state, payload) {
    state.videoData = payload
  },
  setPlan(state, payload) {
    state.planData = payload
  },
  setPhaseDetails(state, payload) {
    state.phaseDetailsData = payload
  },
  setPhaseProperties(state, payload) {
    state.phasePropertiesData = payload
  },
  setPhasePropertiesTotal(state, payload) {
    state.phasePropertiesTotalData = payload
  },
  setRequiredForm(state, payload) {
    state.requiredFormData = payload
  },
}