export default {
    path: '/appointment/:id/details',
    name: 'appointment.details',
    meta: {
      layout: 'vertical'
    },
    component: () => import('@/views/pages/appointmentManagement/Details.vue'),
    meta:{
        breadcrumbs: [
            {
              text: 'Table View',
              to: {name: 'appointment.tableView'}
            },
            {
              text: 'Detail View',
              active: true,
            },
          ],
    }
  }