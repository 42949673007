export default {
  listOfRsData: [],
  sentRequestData: [],
  receivedRequestData: [],
  receivedRequestTotalData: 0,
  sentRequestTotalData: 0,
  rsProjectsTotalData: 0,
  rsProjectsData: [],
  rsMembersData: [],
  rsInformationData: [],
  requestDetailsData: null
}