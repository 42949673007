import Vue from 'vue'
import VueRouter from 'vue-router'
import projectManagement from './projectManagement'
import rsManagement from './rsManagement'
import appointment from './appointmentManagement'
import internalAgentManagement from './internalAgentManagement'
import user from './user'
import auth from './auth'
import contactRequest from './contactRequest'
import leadDetails from './leadDetails'
import rsInvitations from './invitations/rs'
import anaInvitations from './invitations/ana'
import onlineSetting from './onlineSetting'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: { path: '/projects' }
    },
    ...projectManagement,
    ...rsManagement,
    ...appointment,
    ...internalAgentManagement,
    ...user,
    ...auth,
    ...contactRequest,
    ...leadDetails,
    ...rsInvitations,
    ...anaInvitations,
    ...onlineSetting,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const whiteList = [
    'login', 'forgotPassword', 'resetPassword', 'verification', 'invitations.ana.confirm',
    'invitations.rs.accept', 'invitations.rs.information', 'invitations.rs.confirm']
  if (!whiteList.includes(to.name)) {
    if (localStorage.getItem('token') !== null) {
      next()
    } else {
      next({ name: 'login' })
    }
  }
  next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
