import InvitationsService from "@/http/invitations/invitationsService";

export default {
  async createAccount({ commit }, payload) {
    return await InvitationsService.createAccount(payload)
  },
  async verifyAna({ commit }, payload) {
    return await InvitationsService.verifyAna(payload)
  },

  async decline() {
    return await InvitationsService.decline()
  }
}