import InternalAgentService from "@/http/internalAgentsManagement/internalAgentService";

export default {
  async getAgents({ commit }, payload) {
    await InternalAgentService.getAgents(payload).then(response => {
      commit('setAgents', response.data.result.data)
      commit('setAgentsTotal', response.data.result.meta.total)
    })
  },
  async getAgentDetails({ commit }, id) {
    await InternalAgentService.getAgentDetails(id).then(response => {
      commit('setAgentDetails', response.data.result)
    })
  },
  async getAgentFilters({ commit }) {
    await InternalAgentService.getAgentFilters().then(response => {
      commit('setAgentFilters', response.data.result)
    })
  },
  async getAgentOptions({ commit }) {
    await InternalAgentService.getAgentOptions().then(response => {
      commit('setAgentOptions', response.data.result)
    })
  },
  async invite({ commit }, payload) {
    return await InternalAgentService.invite(payload)
  },
  async deleteAgent({ commit }, id) {
    return await InternalAgentService.deleteAgent(id)
  },
  async updateAgent({ commit }, payload) {
    return await InternalAgentService.updateAgent(payload)
  }
}