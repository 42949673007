import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
import i18n from '@/libs/i18n/index'
import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css"

import OtpInput from "@bachdgvn/vue-otp-input";
Vue.component("v-otp-input", OtpInput);


// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.component('multiselect', Multiselect)

// Composition API
Vue.use(VueCompositionAPI)

const VueUploadComponent = require('vue-upload-component')
Vue.component('file-upload', VueUploadComponent)


// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
import "tailwindcss/tailwind.css"
import SvgLoader from "@/views/components/global/SvgLoader";
Vue.component('SvgLoader', SvgLoader)

Vue.config.productionTip = false
export const bus = new Vue();

let vueInstance = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
export default vueInstance