import connected from "@/router/rsManagement/connected";
export default [
  {
    path: '/rs-management',
    name: 'rsManagement',
    redirect: { name: 'rsManagement.connected' },
    component: () => import('@/views/pages/rsManagement/RsManagement.vue'),
    children: [
      connected,
      {
        path: '/rs-management/invite',
        name: 'rsManagement.invite',
        component: () => import('@/views/pages/rsManagement/Invite.vue'),
      },
      {
        path: '/rs-management/sent-request',
        name: 'rsManagement.sentRequest',
        component: () => import('@/views/pages/rsManagement/SentRequest.vue'),
      },
      {
        path: '/rs-management/received-request',
        name: 'rsManagement.receivedRequest',
        component: () => import('@/views/pages/rsManagement/ReceivedRequest.vue'),
      },
    ]
  }
]