export default [
  {
    path: '/online-setting',
    name: 'onlineSetting',
    redirect: { name: 'onlineSetting.branding' },
    meta: {
      layout: 'vertical',
    },
    component: () => import('@/views/pages/onlineSetting/OnlineSetting.vue'),
    children: [
      {
        path: '/online-setting/branding',
        name: 'onlineSetting.branding',
        meta: {
          layout: 'vertical',
        },
        component: () => import('@/views/pages/onlineSetting/Branding.vue'),
      },
      {
        path: '/online-setting/about-us',
        name: 'onlineSetting.aboutUs',
        meta: {
          layout: 'vertical',
        },
        component: () => import('@/views/pages/onlineSetting/Description.vue'),
      },
      {
        path: '/online-setting/media',
        name: 'onlineSetting.media',
        meta: {
          layout: 'vertical',
        },
        component: () => import('@/views/pages/onlineSetting/Media.vue'),
      }
    ]
  }
]