import BaseService from "@/http/baseService";
import DataPreparation from "@/libs/dataPreparation";

export default class LeadDetails {
  static getLead(payload) {
    const url = DataPreparation.apply(payload, '/ana/lead')
    return BaseService.getInstance().getData(url)
  }
  static getSellOperationFilters() {
    return BaseService.getInstance().getData('/ana/sale/filters')
  }
  static getReserveOperationFilters() {
    return BaseService.getInstance().getData('/ana/reserve/filters')
  }
  static setAsSold(payload) {
    return BaseService.getInstance().postData('/ana/sale', payload)
  }
  static setAsReserve(payload) {
    return BaseService.getInstance().postData('/ana/reserve', payload)
  }
}