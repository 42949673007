import AuthService from "@/http/auth/authService";

const state = {}
const getters = {}
const mutations = {}
const actions = {
  async login({ commit }, payload) {
    return await AuthService.login(payload)
  },
  async logout({ commit }) {
    return await AuthService.logout()
  },
  async forgotPassword({ commit }, payload) {
    return await AuthService.forgotPassword(payload)
  },
  async resetPassword({ commit }, payload) {
    return await AuthService.resetPassword(payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}