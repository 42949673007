export default {
  setListOfRs(state, payload) {
    state.listOfRsData = payload
  },
  setSentRequest(state, payload) {
    state.sentRequestData = payload
  },
  setSentRequestTotal(state, payload) {
    state.sentRequestTotalData = payload
  },
  setReceivedRequest(state, payload) {
    state.receivedRequestData = payload
  },
  setReceivedRequestTotal(state, payload) {
    state.receivedRequestTotalData = payload
  },
  setRsMembers(state, payload) {
    state.rsMembersData = payload
  },
  setRsProjects(state, payload) {
    state.rsProjectsData = payload
  },
  setRsProjectsTotal(state, payload) {
    state.rsProjectsTotalData = payload
  },
  setRsInformation(state, payload) {
    state.rsInformationData = payload
  },
  setRequestDetails(state, payload) {
    state.requestDetailsData = payload
  }
}